// Set cookie 'netatmocomlocale' if not already set

import { environment } from '@environments/environment';

// https://www.w3resource.com/javascript-exercises/fundamental/javascript-fundamental-exercise-171.php
const parseCookie = window.parseCookie = str =>
  str
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1]?.trim());
      return acc;
    }, {});

let cookies = parseCookie(document.cookie);
const domain = environment.COOKIE_LOCALE_DOMAIN;

if (!cookies['netatmocomlocale']) {
  const locale = navigator.language.toLowerCase();
  document.cookie = `netatmocomlocale=${locale};domain=${domain};path=/;secure=true`;
}

cookies = parseCookie(document.cookie);

document.documentElement.setAttribute('lang', cookies['netatmocomlocale'] || 'en');
