const chromeRegexp = /\bChrome\/([1-9]|[1-6][0-9]|7[0-4])\b/i;
const ieRegexp = /\bMSIE\s([1-9].0|10.0)\b/i;
const firefoxRegexp = /\bFirefox\/([1-9]|[1-5][0-9]|6[0-9])\b/i;
const safariRegexp = /\b^(?!.*(Chrome|Android|Chromium)).*Version\/([0-9]|1[0-1])(\s|\.).*Safari\b/i;
const regexps = [chromeRegexp, ieRegexp, firefoxRegexp, safariRegexp];
regexps.forEach(function(regexp) {
  if (navigator.userAgent.match(regexp)) {
    window.location.href = '/unsupported_browser.html';
  }
});
