const userSetTheme = window.localStorage.getItem('theme');
/**
 * The below logic is shared with change theme page
 * If logic must be changed here, ensure the change is not also needed on other page
 */
if (
  (window.matchMedia('(prefers-color-scheme: dark)').matches || userSetTheme === 'dark') &&
  userSetTheme !== 'light'
) {
  document.documentElement.setAttribute('data-theme', 'dark');
}
else {
  document.documentElement.setAttribute('data-theme', 'light');
}
